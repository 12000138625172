<template>
  <svg
    :width="props.width"
    :height="props.height"
    viewBox="0 0 20 20"
    role="presentation"
  >
    <g
      :fill="props.iconColor"
      :opacity="props.iconOpacity"
    >
      <path
        fill-rule="evenodd"
        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script setup>
const props = defineProps({
  width: {
    type: [Number, String],
    default: 20
  },
  height: {
    type: [Number, String],
    default: 20
  },
  iconColor: {
    type: String,
    default: '#ffffff'
  },
  iconOpacity: {
    type: [Number, String],
    default: 1
  }
});
</script>
